<template>
    <div class="tryexam">
        <Header/>
        <div class="banner">
            <div class="content">
                <div class="left">
                    <div class="user">
                        <div class="imgs">
                            <img v-if="user.headImg" :src="user.headImg" alt="">
                            <img v-else src="../assets/img/user.png" alt="">
                        </div>
                        <h6>{{user.name}}</h6>
                        <span class="num">工号 {{user.username}}</span>
                        <el-button type="primary" @click="handelto('exam')">综合测试</el-button>
                        <el-button type="info" @click="handelto('')">退出系统</el-button>
                    </div>
                    <div class="list">
                        
                       <el-row class="tac">
                        <el-col :span="24">
                           <el-menu
                                default-active="2"
                                class="el-menu-vertical-demo"
                                active-text-color="#ff5500"
                                router
                            >
                            <el-menu-item index="1" route="/video">
                                <span slot="title">视频学习</span>
                                <img src="../assets/img/home/right.png" alt="">
                            </el-menu-item>
                            <el-menu-item index="2" route="/article">
                                <span slot="title">文章学习</span>
                                <img src="../assets/img/home/right.png" alt="">

                            </el-menu-item>
                            <el-menu-item index="3" route="/tryexam">
                                <span class="active" slot="title">模拟测试</span>
                                <img src="../assets/img/home/right.png" alt="">
                            </el-menu-item>
                            
                            </el-menu>
                        </el-col>
                    </el-row>    
                    </div>
                </div>
                <div class="right">
                    <div class="main">
                        <div class="head">
                            <div class="title">
                                模拟考试
                            </div>
                            <div class="bag">当前位置：
                                <el-breadcrumb separator-class="el-icon-arrow-right" >
                                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                                    <el-breadcrumb-item :to="{ path: '/video' }">课程学习</el-breadcrumb-item>
                                    <el-breadcrumb-item>模拟考试</el-breadcrumb-item>
                                </el-breadcrumb>
                            </div>
                            <div class="xian"></div>
                        </div>
                        <div class="text_list">
                            <div class="screen">
                                <el-select v-model="options.value" :placeholder="options[0].value" @change="changeValue(options.value)">
                                    <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                                <div class="ipt">
                                    <input type="text" v-model="msg" placeholder="输入关键字" >
                                    <div @click="handelseek">搜索</div>
                                    <div @click="handeldel" >清空</div>
                                </div>
                            </div>
                            <div  v-if="this.filterlist.length==0" class="img">
                                <img src="../assets/img/noneir.png" alt="">
                            </div>
                            <div class="box"  v-for="(item,index) in filterlist" :key="index">
                                <div class="box_top">
                                    <div class="title">{{item.title}}</div>
                                    <span v-if="isShow">{{item.categoryTitle}}</span>
                                </div>
                                <div class="box_bottom">
                                    <span>总分：{{item.totalScore}}分</span>
                                    <span>题数：{{item.questionsNumber}}</span>
                                    <span>答题时间：{{item.answerTime}}分钟</span>
                                    <div class="btn">
                                        <button @click="handelGo(item.id)">去做题 ></button>
                                    </div>
                                </div>
                            </div>
                            <el-pagination  v-if="total>6"
                                    class="pag"
                                    small
                                    background
                                    layout="prev, pager, next"
                                    :total="total"
                                    >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import { GetTryexamList,GetTryList } from '../api/list'
    export default {
        components: {
            Header,
            Footer,
        },
        data() {
            return {
                total:0,          //分页总数
                user:'',
                msg:'',         //input框双向绑定
                form: {         //from表单
                    region: '',
                    status:'1'
                },
                isShow:true,    //控制高低频按钮
                options: [{value:''}],     //select下拉框的列表内容
                value: '',      //select下拉框标记
                filterlist:[],  //筛选后的列表
                trylist:[]      //原题库列表
            }
        },
        created () {
            this.user=JSON.parse(window.sessionStorage.getItem("user"))         //用户信息
        },
        methods: {
            changeValue(value){
                this.value=value
                this.msg=''
                console.log(this.total);
                GetTryexamList(this.value,999,1).then(res=>{
                    this.filterlist=res.data.data
                })
                this.options.forEach((item,i)=>{
                    if(this.options[i].value==this.options.value){
                        this.filterlist=[]
                        this.trylist.forEach((item)=>{
                            if(item.categoryTitle==this.options[i].label){
                                if(this.filterlist.length<6){
                                    this.filterlist.push(item)
                                    return false
                                }
                            }
                        })
                    }
                })
                this.total=this.filterlist.length
            },
            getlist(){          
                GetTryList().then(res=>{
                    this.options=res.data.data
                })
                setTimeout(() => {
                    GetTryexamList(this.value,999,1).then(res=>{
                        this.trylist=res.data.data  
                        this.trylist.forEach(item=>{            //初始化时页面显示内容
                            if(item.categoryTitle==this.options[0].label){
                                    this.filterlist.push(item)
                                }
                        })
                    })
                    
                }, 500);
                
            },

            handeldel(){    //清空搜索框
                this.msg=''
            },
            handelseek(){   //搜索
                if(this.msg!==''){
                    this.filterlist=[]
                    this.filterlist = this.trylist.filter(item=>{
                        if(item.title.indexOf(this.msg) != -1){
                            return item
                        }
                    })
                }
            },
            handelto(i){
                this.$router.push('/'+i)
            },
            handelGo(id){
                 this.$fullscreen.enter()
                this.$router.push({
                    path:'/exam',
                    name:'Exam',
                    query:{
                        id:id
                    }
                })
            }
        },
        mounted () {
            this.getlist()
        },
    }
</script>

<style scoped>
@import url(../assets/css/Tryexam.css);
</style>